import React from "react";
import Spinner from "./Spinner";

const PageLoader = () => {
  return (
    <div className="fixed w-full h-full flex justify-center items-center bg-white">
      <Spinner />
    </div>
  );
};

export default PageLoader;
