import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import PageLoader from "./Components/UI/Spinner/PageLoader";
import PageRoutes from "./Routes/routes";
import { BrowserRouter } from "react-router-dom";
import "./i18n";

const root = createRoot(document.getElementById("root"));

root.render(
  <Suspense fallback={<PageLoader />}>
    <BrowserRouter>
      <PageRoutes />
    </BrowserRouter>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
