import React from "react";
import { Route, Routes } from "react-router-dom";
// import App from "../App";
// import Partner from "../Containers/Partner";
// import Home from "../Containers/Home";
// import Category from "../Containers/category";
// import Driver from "../Containers/Driver";
// import Privacy from "../Containers/Privacy";
// import Terms from "../Containers/terms";
// import PageNotFound from "../PageNotFound";
// import Discover from "../Containers/Discover";
// import SingleBlog from "../Components/Blog/SingleBlog";
import { lazy } from "react";

// Lazy loaded components
const App = lazy(() => import("../App"));
const Category = lazy(() => import("../Containers/category"));
const Home = lazy(() => import("../Containers/Home"));
const Partner = lazy(() => import("../Containers/Partner"));
const GetJob = lazy(() => import("../Containers/GetJob"));
const Driver = lazy(() => import("../Containers/Driver"));
const Discover = lazy(() => import("../Containers/Discover"));
const Blog = lazy(() => import("../Containers/Blog"));
const SingleBlog = lazy(() => import("../Components/Blog/SingleBlog"));
const Privacy = lazy(() => import("../Containers/Privacy"));
const Terms = lazy(() => import("../Containers/terms"));
const PageNotFound = lazy(() => import("../PageNotFound"));

const PageRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="category/:id" element={<Category />} />
        <Route path="become-captain" element={<Driver />} />
        <Route path="become-partner" element={<Partner />} />
        <Route path="become-one-of-us" element={<GetJob />} />

        <Route path="blog" element={<Blog />} />
        <Route path="blog/:id" element={<SingleBlog />} />

        {/* <Route path="discover" element={<Discover />} /> */}
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />

        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

// const PageRoutes = createBrowserRouter([
//   {
//     path: "/",
//     element: <App />,
//     children: [
//       {
//         index: true,
//         element: <Home />,
//       },
//       {
//         path: "category/:id",
//         element: <Category />,
//       },
//       {
//         path: "become-captain",
//         element: <Driver />,
//       },
//       {
//         path: "become-partner",
//         element: <Partner />,
//       },
//       {
//         path: "blog",
//         element: <Blog />,
//         children: [{ path: ":id", element: <SingleBlog /> }],
//       },
//       {
//         path: "discover",
//         element: <Discover />,
//       },
//       {
//         path: "privacy",
//         element: <Privacy />,
//       },
//       {
//         path: "terms",
//         element: <Terms />,
//       },
//     ],
//     errorElement: <PageNotFound />,
//   },
// ]);

export default PageRoutes;
